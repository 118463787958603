import sharedDOMPurify from './types/shared-dompurify';

interface SanitizeUrlResult {
  sanitizedUrl: string;
  error: string | null;
}

export function validateAndSanitizeUrl(inputUrl: string, allowedSource?: string): SanitizeUrlResult {
  try {
    const parsedUrl = new URL(inputUrl);

    if (parsedUrl.protocol !== 'https:') {
      throw new Error('Only HTTPS URLs are allowed');
    }

    if (allowedSource && parsedUrl.hostname !== allowedSource) {
     if (process.env.NODE_ENV === 'development') {
       throw new Error(`URL must be from ${allowedSource}`);
     }
    }

    const sanitizedUrl = sharedDOMPurify.sanitize(parsedUrl.toString());
    return { sanitizedUrl, error: null };
  } catch (err) {
    return { sanitizedUrl: '', error: err instanceof Error ? err.message : 'Invalid URL' };
  }
}
